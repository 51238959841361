import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { DatePipe } from "@angular/common";
import {
  CHANNEL_EXCEL_HEADER,
  CITY_EXCEL_HEADER,
  CITY_UPLOAD_HEADER,
  GEOGRAPHY_EXCEL_HEADER,
  LOYALTY_PRODUCT_HEADER,
  ORDER_DETAILS_EXCEL,
  POINT_STRUCTURE_EXCEL,
  REGION_EXCEL_HEADER,
  REGION_UPLOAD_HEADER,
  STATE_EXCEL_HEADER,
  STATE_UPLOAD_HEADER,
  TARGET_EXCEL,
  USER_EXCEL,
  WHITELISTING_EXCEL,
  REWARDS_SUB_CATEGORIES_EXCEL,
  REWARDS_BRAND_EXCEL,
} from "src/app/hubadmin/shared/models/mock-data";
import { BrandModel } from "src/app/hubadmin/shared/models/brands";
import { GeographyModel } from "src/app/hubadmin/shared/models/geography";
import { ChannelModel } from "src/app/hubadmin/shared/models/channel";
import { RegionModel } from "src/app/hubadmin/shared/models/region";
import { UserRoleModel } from "src/app/hubadmin/shared/models/user-roles";
import { RewardProvider } from "src/app/hubadmin/shared/providers/rewards.provider";

@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  DownloadCommonExcelWithOptionalDropDown;
  // Redmeption
  TargetHeaders = TARGET_EXCEL;
  PointStructureHeaders = POINT_STRUCTURE_EXCEL;
  WhiteListingHeaders = WHITELISTING_EXCEL;
  userHeaders = USER_EXCEL;
  stateHeaders = STATE_EXCEL_HEADER;
  geoHeaders = GEOGRAPHY_EXCEL_HEADER;
  regionHeaders = REGION_EXCEL_HEADER;
  channelHeaders = CHANNEL_EXCEL_HEADER;
  cityHeaders = CITY_EXCEL_HEADER;
  regionUploadHeaders = REGION_UPLOAD_HEADER;
  stateUploadHeaders = STATE_UPLOAD_HEADER;
  cityUploadHeaders = CITY_UPLOAD_HEADER;
  loyaltyProductHeaders = LOYALTY_PRODUCT_HEADER;

  constructor(
    private datePipe: DatePipe,
    public rewardProviders: RewardProvider
  ) {}

  pipe = new DatePipe("en-US");

  public DownloadCommonExcel(name, sheetname, tableData, excelArray) {
    let header = tableData.headers;

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  commonPrintHeaders(headers, filename, sheetname) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers;
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let worksheet2 = workbook.addWorksheet("Sheet2");

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        filename + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportExcel(brandData: BrandModel[]) {
    const header = this.TargetHeaders;
    const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    const Brands = filterBrands.map((i) => i.sku);
    const finalHeaders = header.concat(Brands);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Target Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B9D0E8" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Target_Excel-Monthly_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }

  exportAchievementsExcel(brandData: BrandModel[]) {
    const header = this.TargetHeaders;
    // const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    // const Brands = filterBrands.map((i) => i.sku);
    const finalHeaders = header;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Sales Achievement Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F79494" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    // worksheet.getCell('C2').value = {}

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Sales_Achievements_Excel-Monthly_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }

  exportPointExcel(brandData: BrandModel[], outletCount: any) {
    const finalHeaders = this.PointStructureHeaders;
    const productHeaders = this.loyaltyProductHeaders;
    const filterBrands = brandData.filter((i) => i.is_loyalty == 1);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Point Structure Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /****************************PRODUCTS*************************************/

    let productWorksheet = workbook.addWorksheet("Loyalty_Products", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(this.loyaltyProductHeaders);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [
      { header: "Product Name", key: "brand_desc" },
      { header: "SKU", key: "sku" },
      { header: "Product Short Name", key: "brand_name" },
      // { header: "Unit", key: "unit_id" },
    ];

    brandData.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    /*************************************************************************/

    // let productFormulae = [
    //   `${productWorksheet.name}!B2:B${filterBrands.length + 1}`,
    // ];

    // let iterationLength = filterBrands.length + 1;

    // if(outletCount > 0) {

    //   iterationLength = outletCount * filterBrands.length + 1;
    // }

    // for (var i = 2; i <= iterationLength; i++) {
    //   worksheet.getCell(`B${i}`).dataValidation = {
    //     type: "list",
    //     allowBlank: false,
    //     formulae: productFormulae,
    //     errorStyle: "error",
    //     errorTitle: "choose a role",
    //     error: "please select",
    //     showErrorMessage: true,
    //   };
    // }

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Point_Structure_Excel-Monthly_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }

  exportOrderDetailsExcel() {
    const finalHeaders = ORDER_DETAILS_EXCEL;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Order Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /****************************PRODUCTS*************************************/

    let productWorksheet = workbook.addWorksheet("Status", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    let status = [
      { id: 1, name: "Placed" },
      { id: 2, name: "Accepted" },
      { id: 3, name: "Fulfilled" },
      { id: 4, name: "Rejected" },
      { id: 0, name: "Pending" },
    ];

    let STATUS = ["Status"];

    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(STATUS);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [{ header: "Status", key: "name" }];

    status.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    /*************************************************************************/

    let productFormulae = [`${productWorksheet.name}!A2:A${status.length + 1}`];

    for (var i = 2; i <= 50; i++) {
      worksheet.getCell(`F${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: productFormulae,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };
    }

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Order_Details-Monthly_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }

  exportWhiteListExcel(modules: any[]) {
    const header = this.WhiteListingHeaders;
    const modulesHeaders = modules.map((i) => i.module_name);
    const finalHeaders = header.concat(modulesHeaders);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Whitelisting Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Whitelisting_Excel_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }

  exportUserExcel(
    geography: GeographyModel[],
    channel: ChannelModel[],
    region: RegionModel[],
    role: UserRoleModel[],
    state: any[],
    city: any[]
  ) {
    const finalHeaders = this.userHeaders;
    const geographies = geography.map((i) => i.geograph_name.trim()).join(",");
    const channels = channel.map((i) => i.channel_desc.trim()).join(",");
    const regions = region.map((i) => i.region_name.trim()).join(",");
    const roles = role.map((i) => i.role_name.trim()).join(",");
    const cities = city.map((i) => i.cityname.trim()).join(",");
    const states = state.map((i) => i.state_name.trim()).join(",");

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Users", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /******GEO WORKSHEET********/
    let geographyWorksheet = workbook.addWorksheet("Geography", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let geoHeaderRow = geographyWorksheet.addRow(this.geoHeaders);

    // Cell Style : Fill and Border
    geoHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    geographyWorksheet.columns = [
      { header: "Geography", key: "geograph_name" },
    ];

    geography.forEach((d) => {
      let row = geographyWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    geographyWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /******GEO WORKSHEET********/

    /************STATE WORKSHEET***********************/

    let stateWorksheet = workbook.addWorksheet("States", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(this.stateHeaders);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "States", key: "state_name" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    /************CHANNEL WORKSHEET***********************/

    let channelWorksheet = workbook.addWorksheet("Channels", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let channelHeaderRow = channelWorksheet.addRow(this.channelHeaders);

    // Cell Style : Fill and Border
    channelHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    channelWorksheet.columns = [{ header: "Channels", key: "channel_desc" }];

    channel.forEach((d) => {
      let row = channelWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    channelWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    /************REGION WORKSHEET***********************/

    let regionWorksheet = workbook.addWorksheet("Regions", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let regionHeaderRow = regionWorksheet.addRow(this.regionHeaders);

    // Cell Style : Fill and Border
    regionHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    regionWorksheet.columns = [{ header: "Regions", key: "region_name" }];

    region.forEach((d) => {
      let row = regionWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    regionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    /************CITY WORKSHEET***********************/

    let cityWorksheet = workbook.addWorksheet("Cities", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let cityHeaderRow = cityWorksheet.addRow(this.cityHeaders);

    // Cell Style : Fill and Border
    cityHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    cityWorksheet.columns = [{ header: "Cities", key: "cityname" }];

    city.forEach((d) => {
      let row = cityWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    regionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /***************************************************/

    // Specify list of valid values (One, Two, Three, Four).
    // Excel will provide a dropdown with these values.

    let rolesData = this.excelDataValidationSplit(roles);

    let GeoFormulae = [`${geographyWorksheet.name}!A2:A${geography.length}`];

    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length}`];

    let regionFormulae = [`${regionWorksheet.name}!A2:A${region.length}`];

    let cityFormulae = [`${cityWorksheet.name}!A2:A${city.length}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: rolesData,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`E${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: GeoFormulae,
        errorStyle: "error",
        errorTitle: "choose a geography",
        error: "please select",
        showErrorMessage: true,
      };
      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormulae,
        promptTitle: "Region",
        prompt: "Select any region",
        showErrorMessage: true,
      };

      worksheet.getCell(`H${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`O${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        showErrorMessage: true,
        formulae: cityFormulae,
        errorStyle: "error",
        errorTitle: "choose a city",
        error: "please select",
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Add_User_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  excelDataValidationSplit(item) {
    let data = item.split(",").join(",");
    data = '"' + data + '"';
    return [`${data}`];
  }

  /**
   * Kailash_dev
   * Brand list export
   */
  exportBrandExcel(headers, title, dropDown) {
    // console.log(dropDown);
    const finalHeaders = headers;
    let categoryHeader = ["Category"];
    let subCategoryHeader = ["Sub Category"];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add New Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      // cell.protection = { locked: true };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /*********************category Starts**********************************/
    /** adding category drop down */
    let categoryWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let categoryHeaderRow = categoryWorksheet.addRow(categoryHeader);

    // Cell Style : Fill and Border
    categoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    categoryWorksheet.columns = [{ header: "Category", key: "category_name" }];

    // dropDown.category.forEach((d) => {
    //   let row = categoryWorksheet.addRow(d);
    //   (row.font = {
    //     name: "Calibri",
    //     size: 8,
    //   }),
    //     (row.alignment = {
    //       vertical: "middle",
    //       horizontal: "center",
    //     });
    // });

    categoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // let categoryFormulae = [`${categoryWorksheet.name}!A2:A${dropDown.category.length}`];

    // for (var i = 2; i < 50; i++) {
    //   worksheet.getCell(`H${i}`).dataValidation = {
    //     type: "list",
    //     allowBlank: false,
    //     formulae: categoryFormulae,
    //     errorStyle: "error",
    //     errorTitle: "choose a state",
    //     error: "please select",
    //     showErrorMessage: true,
    //   };
    // }

    /**************************************************************/

    let subCategoryWorksheet = workbook.addWorksheet("Sub_Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let subCategoryHeaderRow = subCategoryWorksheet.addRow(subCategoryHeader);

    // Cell Style : Fill and Border
    subCategoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    subCategoryWorksheet.columns = [
      { header: "Sub Category", key: "sub_category_name" },
    ];

    dropDown.subCategory.forEach((d) => {
      let row = subCategoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    subCategoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let subCategoryFormulae = [
      `${subCategoryWorksheet.name}!A2:A${dropDown.subCategory.length}`,
    ];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`K${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: subCategoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        title + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportRegionExcel() {
    const finalHeaders = this.regionUploadHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Region Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Region_Excel_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }

  exportStateExcel(region: any[]) {
    const finalHeaders = this.stateUploadHeaders;
    console.log("s", this.stateUploadHeaders);
    console.log("r", this.regionHeaders);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("State Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /************REGION WORKSHEET***********************/

    let regionWorksheet = workbook.addWorksheet("Regions", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let regionHeaderRow = regionWorksheet.addRow(this.regionHeaders);

    // Cell Style : Fill and Border
    regionHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    regionWorksheet.columns = [{ header: "Regions", key: "region_name" }];

    region.forEach((d) => {
      let row = regionWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    regionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**************************************************************/

    let regionFormulae = [`${regionWorksheet.name}!A2:A${region.length}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormulae,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "State_Excel_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportTargetsExcel(headers, categories) {
    console.log("e cat ", categories);
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add Target Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /************Categories WORKSHEET***********************/

    let categoryWorkSheet = workbook.addWorksheet("Targets", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    // Add Header Row
    console.log("region header", this.regionHeaders);
    let catHeaderRow = categoryWorkSheet.addRow([]);

    // Cell Style : Fill and Border
    catHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    categoryWorkSheet.columns = [{ header: "", key: "brand_category_name" }];

    categories.forEach((d) => {
      let row = categoryWorkSheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    categoryWorkSheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let regionFormulae = [`${categoryWorkSheet.name}!A2:A${categories.length}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormulae,
        errorStyle: "error",
        errorTitle: "Category Required",
        error: "Please select a category",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Add_target_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportCityExcel(state: any[]) {
    const finalHeaders = this.cityUploadHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("City Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**************************************************************/

    let stateWorksheet = workbook.addWorksheet("States", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(this.stateHeaders);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "States", key: "state_name" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "City_Excel_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  /**
   * Kailash_dev
   * Brand list export
   */
  exportRewardsExcel(headers, title, dropDown) {
    console.log(dropDown);
    const finalHeaders = headers;
    let categoryHeader = ["Category"];
    let subCategoryHeader = ["Sub Category"];
    let brandHeader = ["Brands"];
    let priceHeader = ["Price"];
    let productHeader = ["Product Types"];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add New Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      // cell.protection = { locked: true };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /*********************category Starts**********************************/
    /** adding category drop down */
    let categoryWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let categoryHeaderRow = categoryWorksheet.addRow(categoryHeader);

    // Cell Style : Fill and Border
    categoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    categoryWorksheet.columns = [{ header: "Category", key: "category_name" }];

    dropDown.category.forEach((d) => {
      let row = categoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    categoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let categoryFormulae = [
      `${categoryWorksheet.name}!A2:A${dropDown.category.length + 1}`,
    ];

    /**************************************************************/

    let subCategoryWorksheet = workbook.addWorksheet("Sub_Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let subCategoryHeaderRow = subCategoryWorksheet.addRow(subCategoryHeader);

    // Cell Style : Fill and Border
    subCategoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    subCategoryWorksheet.columns = [
      { header: "Sub Category", key: "sub_category_name" },
    ];

    dropDown.subCategory.forEach((d) => {
      let row = subCategoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    subCategoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let subCategoryFormulae = [
      `${subCategoryWorksheet.name}!A2:A${dropDown.subCategory.length + 1}`,
    ];

    /**************************** Brands **********************************/

    let brandWorksheet = workbook.addWorksheet("Brands", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let brandHeaderRow = brandWorksheet.addRow(brandHeader);

    // Cell Style : Fill and Border
    brandHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    brandWorksheet.columns = [{ header: "Brands", key: "brand_name" }];

    dropDown.brands.forEach((d) => {
      let row = brandWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    brandWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let brandFormulae = [
      `${brandWorksheet.name}!A2:A${dropDown.brands.length + 1}`,
    ];

    /**************************** Price Drop down **********************************/
    let priceWorksheet = workbook.addWorksheet("Price_Types", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let priceHeaderRow = priceWorksheet.addRow(priceHeader);

    // Cell Style : Fill and Border
    priceHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    priceWorksheet.columns = [{ header: "Price", key: "price_type" }];

    dropDown.price_type.forEach((d) => {
      let row = priceWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    priceWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let priceFormulae = [
      `${priceWorksheet.name}!A2:A${dropDown.price_type.length + 1}`,
    ];

    /**********************Product types***********************************/

    let productWorksheet = workbook.addWorksheet("Product_Types", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(productHeader);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [
      { header: "Product Types", key: "product_type" },
    ];

    dropDown.product_types.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let productFormulae = [
      `${productWorksheet.name}!A2:A${dropDown.product_types.length}`,
    ];

    /**Adding drop into cells */
    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`E${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: categoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`F${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: subCategoryFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };

      worksheet.getCell(`G${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: brandFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
      worksheet.getCell(`H${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: priceFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: productFormulae,
        errorStyle: "error",
        errorTitle: "choose a Product",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        title + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  /**
   *
   * @param Download Checker and maker report
   * @param tableData
   * @param excelArray
   */
  public DownloadReportExcel(name, tableData, excelArray) {
    let header = tableData.headers;

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "1E88E5" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.body.length > 0) {
      tableData.body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name +
          "- HubAdmin -" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }

  /**
   * @param brandCategories
   *
   */
  exportbrandCategoriesExcel(header, excelName) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add Brand Category Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    worksheet.columns = [];
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        excelName +
          "_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }

  // *****************************************************************************************

  // Rejected users
  exportRejectedUsersExcel(excelData) {
    const finalHeaders = excelData[0].headers;
    // const states = state.map((i) => i.state_name.trim()).join(",");
    console.log("looking", excelData);
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData[0].name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = excelData[0].column;
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData[0].body.length > 0) {
      excelData[0].body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    /************Other roles***********************/

    excelData.shift();

    excelData.forEach((userData, i) => {
      let otherRoleSheet = workbook.addWorksheet(userData.name, {
        pageSetup: {
          horizontalCentered: true,
          verticalCentered: true,
          paperSize: 9,
          orientation: "portrait",
          margins: {
            left: 0.3149606,
            right: 0.3149606,
            top: 0.3543307,
            bottom: 0.3543307,
            header: 0.3149606,
            footer: 0.3149606,
          },
        },
      });

      //Add Header Row
      let stateHeaderRow = otherRoleSheet.addRow(userData.headers);

      // Cell Style : Fill and Border
      stateHeaderRow.eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "9999FF" },
          bgColor: { argb: "FF0000FF" },
        };
        cell.font = {
          bold: true,
          name: "Calibri",
          size: 8,
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      otherRoleSheet.columns = userData.column;

      userData.body.forEach((d) => {
        let row = otherRoleSheet.addRow(d);
        (row.font = {
          name: "Calibri",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });

      otherRoleSheet.columns.forEach(function (column, i) {
        column.width = 26;
      });
    });

    /***************************************************/

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Add_User_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  // Failed useres export

  exportFailedEnrollmentsExcel(excelData, column) {
    const finalHeaders = excelData.headers;
    // const states = state.map((i) => i.state_name.trim()).join(",");

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Failed enrollments", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = column;
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData.failed_verifications.length > 0) {
      excelData.failed_verifications.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 8,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Add_User_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  // **************************************************************************************************
  // Export excel general

  exportExcel_with_header(headers, excel_name) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excel_name, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        excel_name +
          "_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }

  // *************************************************************************************************
  // COMMON FUNCTION, pass headers
  commonPrintHeaders1(headers, fileName) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("headers", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers;
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        fileName + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  //CK Added
  exportRewardSubCategories() {
    const finalHeaders = REWARDS_SUB_CATEGORIES_EXCEL;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Rewards Sub Categories", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**************************** DropDown*************************************/

    let productWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    let category = this.rewardProviders.rewardCategory;
    let CAT_ADD = ["Category"];

    //Add Header Row
    let productHeaderRow = productWorksheet.addRow(CAT_ADD);

    // Cell Style : Fill and Border
    productHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    productWorksheet.columns = [{ header: "Category", key: "category_name" }];

    category.forEach((d) => {
      let row = productWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 8,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    let productFormulae = [
      `${productWorksheet.name}!A2:A${category.length + 1}`,
    ];

    for (var i = 2; i <= 50; i++) {
      worksheet.getCell(`C${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: productFormulae,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };
    }

    productWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Rewards_Sub_Categories_Monthly_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }
  exportRewardBrand() {
    const finalHeaders = REWARDS_BRAND_EXCEL;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Reward Brands", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Rewards_Brands_Monthly_" +
          this.datePipe.transform(new Date(), "medium") +
          ".xlsx"
      );
    });
  }
  // COMMON FUNCTION, pass headers
  commonPrintHeaders2(headers) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("headers", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers;
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "User-enroll-" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  //   COMMON FUNCTION, pass headers
  commonPrintHeaders11(headers, filename, sheetname) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers;
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        filename + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  commonPrintHeadersTwo(headers, filename, sheetname) {
    const finalHeaders = headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });

    worksheet.columns = headers;
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        filename + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }
}
