import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, pipe } from "rxjs";
import {
  map,
  catchError,
  retryWhen,
  delay,
  take,
  tap,
  finalize,
} from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthenticationCheckService } from "../auth/authentication-check.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastClientService } from "../toast/toast-client.service";
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  /**
   * @author kailash_dev
   * @param httpInterceptor
   * Whenever calling Api this interceptor will inject AUTH_Token and enable loader
   * < Write less & code more />
   */
  constructor(
    private auth: AuthenticationCheckService,
    private ngxService: NgxUiLoaderService,
    private toast: ToastClientService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //Authentication by setting header with token value
    let disableloader = request.headers.get("skip");
    let token = this.auth.getAuthToken();
    // console.log(token);
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token,
        },
      });
    }

    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        setHeaders: {
          // 'content-type': 'application/json'
        },
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });
    if (disableloader != "true") {
      this.loaderStart();
    }
    return next.handle(request).pipe(
      // retryWhen(err=>{
      //     let retries =1;
      //     return err.pipe(
      //         delay(1000),
      //         tap(()=>{
      //             console.log("Retry");
      //         }),
      //         map(error=>{
      //             if(retries++ === 3){
      //                 return error
      //             }
      //             return error
      //         })
      //     )
      // }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "";
        let toasterMessage = "";
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
          toasterMessage = error.error.message;
        } else {
          // server-side error
          toasterMessage = error.error.data.message;
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        this.toast.Error(toasterMessage);
        this.loaderDismiss();
        return throwError(errorMessage);
      }),
      finalize(() => {
        this.loaderDismiss();
      })
    );
  }
  loaderStart() {
    try {
      this.ngxService.start();
    } catch (error) {
      console.log(error);
    }
  }
  loaderDismiss() {
    try {
      // this.loadingCntr.dismiss();
      this.ngxService.stop();
    } catch (error) {}
  }
}
